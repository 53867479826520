<template>
  <div class="reservation-cancel-no-flag">
    <div class="reservation-cancel-no-flag--content">
      <p>This Reservation will be moved to Cancelled status</p>
      <CRForm ref="cancel-form">
        <CRTextArea
          id="reservation-cancellation-notes-text-area"
          v-model="notes"
          placeholder="Record information about the cancellation here. It will be saved as a comment."
          :rows="4"
          auto-grow
          validate-on-blur
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Information about the Cancellation is Required',
              error: 'Information about the Cancellation is Required',
            }),
          ]"
        />
      </CRForm>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div
      id="reservation-cancellation-cancel-button"
      class="cr-sidebar-dialog--action-btn"
      @click="submitForm"
    >
      <span v-if="!loading">Cancel Trip</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {},
  data() {
    return {
      isRequired,
      isNotEmpty,
      notes: '',
      loading: false,
      isEmpty: '',
    }
  },
  computed: {
    ...mapState('reservations', {
      reservationsCache: (state) => {
        return state.reservationsCache
      },
    }),
    reservationId() {
      return this.$route.params.id
    },
    reservation() {
      return this.reservationsCache[this.reservationId]
    },
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog') // TODO: make this vuex's concern. Put in 'reservations'.
    },
    async submitForm() {
      this.loading = true

      if (!this.$refs['cancel-form'].validate()) {
        this.loading = false
        return
      }

      const params = {
        reservation: {
          id: this.reservationId,
        },
        note: this.comment,
      }

      await this.$store.dispatch(
        'reservations/cancelReservationsWithComment',
        params
      )

      this.$store.dispatch('app/showAlert', {
        color: 'success',
        message: 'Reservation Cancelled.',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-cancel-no-flag {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px 48px;
  }
}
</style>
